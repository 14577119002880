/* ProfileSelection.css */

.profile-selection {
    display: flex;
    justify-content: center; /* Change to center to bring the squares closer */
}

.profile-square {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 5px; /* Add some margin between the squares */
}

.profile-square:first-child {
    background: linear-gradient(to right, #a1c4fd, #c2e9fb); /* Gradient for the first square */
}

.profile-square:last-child {
    background: linear-gradient(to right, #fbc2eb, #a6c1ee); /* Gradient for the second square */
}